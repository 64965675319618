body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body * {
	box-sizing: border-box;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

button {
	background: inherit;
	border: none;
	cursor: pointer;
	padding: 0;
}

button:disabled {
	cursor: default;
}

a {
	cursor: pointer;
}

ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
